import React, { useEffect, useState } from 'react';
import { Loader, Heading, Button, ToggleButtonGroup, Spacer } from '@osag/pyrene';

import { useIntl } from 'react-intl';
import { useApi } from 'context/api';
import { Social } from 'context/api/types';
import { useSelection } from 'context/selection';
import { useFirebase } from 'context/firebase';
import Card from 'components/card';
import styles from './socialtime.css';

const SocialTimeView = (): React.ReactElement => {
  const intl = useIntl();
  const api = useApi();
  const { logEvent } = useFirebase();

  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(0);
  const [socialMinutes, setSocialMinutes] = useState<number | null>(null);
  const { angel } = useSelection();

  useEffect(() => {
    if (angel?.id) {
      setLoading(true);
      api
        .getSocialTime(angel.id)
        .then((s) => {
          updateSocialMinutes(s);
          setLoading(false);
        })
        .catch(() => {
          /* FIXME */
        });
    }
  }, [angel?.id, api]);

  useEffect(() => {
    setDuration(socialMinutes ?? 15);
  }, [socialMinutes]);

  const updateSocialMinutes = (social: Social) => {
    if (social?.time) {
      const minutes = Math.floor((social.time.getTime() - new Date().getTime()) / 60000);
      setSocialMinutes(minutes > 0 ? minutes : null);
    } else {
      setSocialMinutes(null);
    }
  };

  const onSubmit = () => {
    if (angel?.id) {
      setSubmitting(true);
      api
        .setSocialTime(angel?.id, duration)
        .then((s) => {
          updateSocialMinutes(s);
          setSubmitting(false);
        })
        .catch(() => {
          /* FIXME */
        });
      logEvent('Config:Social:Set', { duration });
    }
  };

  const onClear = () => {
    if (angel?.id) {
      setSubmitting(true);
      api
        .clearSocialTime(angel?.id)
        .then((s) => {
          updateSocialMinutes(s);
          setSubmitting(false);
        })
        .catch(() => {
          /* FIXME */
        });
      logEvent('Config:Social:Set', { duration });
    }
  };

  const options = [
    {
      label: intl.formatMessage({ id: 'config.social.duration' }, { minutes: 15, hours: 0 }),
      value: '15',
    },
    {
      label: intl.formatMessage({ id: 'config.social.duration' }, { minutes: 0, hours: 1 }),
      value: '60',
    },
    {
      label: intl.formatMessage({ id: 'config.social.duration' }, { minutes: 0, hours: 3 }),
      value: '180',
    },
  ];

  if (socialMinutes && !options.map((o) => o.value).includes(socialMinutes.toString())) {
    options.push({
      label: intl.formatMessage(
        { id: 'config.social.duration' },
        { minutes: socialMinutes % 60, hours: Math.floor(socialMinutes / 60) }
      ),
      value: socialMinutes.toString(),
    });
    options.sort((a, b) => Number(a.value) - Number(b.value));
  }

  if (!angel || loading) {
    return (
      <Card>
        <>
          <Heading level={3}>{intl.formatMessage({ id: 'config.social.title' })}</Heading>
          <Loader type="inline" />
        </>
      </Card>
    );
  }

  return (
    <Card>
      <>
        <Heading level={3}>{intl.formatMessage({ id: 'config.social.title' })}</Heading>
        {intl.formatMessage({ id: 'config.social.info' })}
        <Spacer size="small" />
        <div className={styles.inputAndButton}>
          <div className={styles.input}>
            <ToggleButtonGroup
              disabled={submitting}
              onChange={(v) => setDuration(Number(v))}
              options={options}
              value={`${duration}`}
            />
          </div>
          {socialMinutes && !submitting && (
            <div className={styles.button}>
              <Button
                type="secondary"
                label={intl.formatMessage({ id: 'config.social.clear' })}
                onClick={() => onClear()}
                loading={submitting}
              />
            </div>
          )}
          {socialMinutes ? (
            <div className={styles.button}>
              <Button
                label={intl.formatMessage({ id: 'config.social.update' })}
                onClick={() => onSubmit()}
                loading={submitting}
                disabled={socialMinutes === duration}
              />
            </div>
          ) : (
            <div className={styles.button}>
              <Button
                label={intl.formatMessage({ id: 'config.social.activate' })}
                onClick={() => onSubmit()}
                loading={submitting}
              />
            </div>
          )}
        </div>
      </>
    </Card>
  );
};

export default SocialTimeView;
