import React, { useEffect, useState } from 'react';
import { Modal, MultiSelect, Spacer, TextField } from '@osag/pyrene';

import { useIntl } from 'react-intl';
import { useApi } from 'context/api';
import { useSelection } from 'context/selection';
import { useFirebase } from 'context/firebase';
import { CategoryMap, ProfileDetails } from 'context/api/types';
import { useLang } from 'context/lang';

type Props = {
  profileShort: string | null;
  onClose: () => void;
};

const ProfileDetailModal = ({ profileShort, onClose }: Props): React.ReactElement => {
  const intl = useIntl();
  const { language } = useLang();

  const { getProfileDetails, getCategories, createProfile, updateProfile } = useApi();
  const { logEvent } = useFirebase();

  const [selectedCategories, setSelectedCategories] = useState<string[] | null>(null);
  const [editedName, setEditedName] = useState<string>('');
  const [editedDescription, setEditedDescription] = useState<string>('');

  const [profileDetails, setProfileDetails] = useState<ProfileDetails | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [categories, setCategories] = useState<CategoryMap | null>(null);
  const { angel } = useSelection();

  const onSubmit = () => {
    if (angel?.id && editedName && editedDescription) {
      if (profileShort) {
        setSubmitting(true);
        logEvent('Config:Profile:Update', { profile: profileShort, angelId: angel.id });
        updateProfile(angel.id, profileShort, {
          name: editedName,
          description: editedDescription,
          lists: selectedCategories ?? [],
        })
          .then(() => {
            setSubmitting(false);
            onClose();
          })
          .catch(() => {
            /* FIXME */
          });
      } else {
        setSubmitting(true);
        logEvent('Config:Profile:Default', { profile: editedName || '', angelId: angel.id });
        createProfile(angel.id, {
          name: editedName,
          description: editedDescription,
          lists: selectedCategories ?? [],
        })
          .then(() => {
            setSubmitting(false);
            onClose();
          })
          .catch(() => {
            /* FIXME */
          });
      }
    }
  };

  useEffect(() => {
    getCategories()
      .then((c) => setCategories(c))
      .catch(() => {
        /* FIXME */
      });
  }, [getCategories]);

  useEffect(() => {
    if (angel?.id && profileShort) {
      getProfileDetails(angel.id, profileShort)
        .then((p) => {
          setProfileDetails(p);
        })

        .catch(() => {
          /* FIXME */
        });
    }
  }, [angel, getProfileDetails, profileShort]);

  useEffect(() => {
    if (profileDetails && categories) {
      setSelectedCategories(profileDetails.lists);
      setEditedName(profileDetails?.[`name_${language}` as 'name_en' | 'name_de'] || '');
      setEditedDescription(profileDetails?.[`desc_${language}` as 'desc_en' | 'desc_de'] || '');
    }

    if (!profileShort) {
      setSelectedCategories(null);
      setEditedName('');
      setEditedDescription('');
    }
  }, [profileDetails, profileShort, categories, language]);

  const canSubmit =
    !submitting && (!profileShort || profileDetails?.custom) && editedName && editedDescription;

  return (
    <Modal
      rightButtonBarElements={[
        {
          type: 'secondary',
          label: intl.formatMessage({
            id: 'config.profile.edit.action.cancel',
          }),
          action: () => {
            onClose();
          },
        },
        {
          type: 'primary',
          label: intl.formatMessage({
            id: `config.profile.edit.action.${profileShort ? 'edit' : 'add'}`,
          }),
          action: () => onSubmit(),
          loading: submitting,
          disabled: !canSubmit,
        },
      ]}
      loading={(!profileDetails && !!profileShort) || !categories}
      renderCallback={() =>
        (profileDetails || !profileShort) && categories ? (
          <>
            <MultiSelect
              onBlur={() => null}
              onChange={(v) => setSelectedCategories(v?.map((c) => c.value) ?? [])}
              onFocus={() => null}
              disabled={(profileDetails && !profileDetails.custom) || false}
              options={Object.entries(categories).map(([k, v]) => ({
                value: k,
                label: v[`name_${language}` as 'name_en' | 'name_de'],
              }))}
              value={
                selectedCategories?.map((k) => ({
                  value: k,
                  label: categories[k][`name_${language}` as 'name_en' | 'name_de'],
                })) ?? undefined
              }
              sorted
              title={intl.formatMessage({ id: 'config.profile.edit.categories' })}
            />
            <Spacer size="small" />
            <TextField
              disabled={(profileDetails && !profileDetails.custom) || false}
              title={intl.formatMessage({ id: 'config.profile.edit.name' })}
              value={editedName}
              onChange={(v) => setEditedName(v)}
            />
            <Spacer size="small" />
            <TextField
              disabled={(profileDetails && !profileDetails.custom) || false}
              title={intl.formatMessage({ id: 'config.profile.edit.description' })}
              value={editedDescription}
              onChange={(v) => setEditedDescription(v)}
            />
            <Spacer size="xxxlarge" />
          </>
        ) : (
          <span />
        )
      }
      size="small"
      title={intl.formatMessage({ id: 'config.profile.edit.title' })}
      onClose={() => onClose()}
    />
  );
};

export default ProfileDetailModal;
