import React, { useEffect } from 'react';
import { Modal, Spacer, Link as PyreneLink } from '@osag/pyrene';
import QRCode from 'react-qr-code';

import { useIntl } from 'react-intl';
import { useFirebase } from 'context/firebase';
import styles from './scan.css';

export interface Props {
  onClose: () => void;
  pin: string;
}

const Scan = ({ onClose, pin }: Props): React.ReactElement => {
  const intl = useIntl();
  const { logEvent } = useFirebase();
  const { getConfig } = useFirebase();
  const registerDomain = getConfig('device_register_domain')?.asString() || 'device.sighub.io';

  useEffect(() => {
    logEvent('Config:Clients:Scan', { pin });
  }, [pin, logEvent]);

  return (
    <Modal
      rightButtonBarElements={[
        {
          type: 'secondary',
          label: intl.formatMessage({ id: 'config.scan.close' }),
          action: () => onClose(),
        },
      ]}
      renderCallback={() => (
        <>
          {intl.formatMessage({ id: 'config.scan.info' })}
          <Spacer size="medium" />
          <div className={styles.qrCenter}>
            <QRCode value={`https://${registerDomain}?pin=${pin}`} />
          </div>
          <Spacer size="small" />
          <PyreneLink
            path={`https://${registerDomain}?pin=${pin}`}
            label={`https://${registerDomain}?pin=${pin}`}
          />
        </>
      )}
      size="small"
      title={intl.formatMessage({ id: 'config.scan.title' })}
      onClose={() => onClose()}
    />
  );
};

export default Scan;
