import axios, { AxiosInstance, AxiosResponse } from 'axios';
import {
  Client,
  Angel,
  AngelInfo,
  User,
  Profile,
  AngelPatch,
  Domain,
  AuthRequest,
  Category,
  ProfileDetails,
  CategoryMap,
  ProfileUpdate,
  StatsSummary,
  Social,
} from './types';

export default class Api {
  axios: AxiosInstance;

  token: string | null;

  angelUpdateCallback: (angel: Angel) => void;

  constructor(apiUrl: string, token?: string) {
    this.axios = axios.create({
      baseURL: apiUrl,
    });

    this.angelUpdateCallback = () => {
      /* */
    };

    if (token) {
      this.token = token;
      this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      this.token = null;
      delete this.axios.defaults.headers.common.Authorization;
    }
  }

  onAngelUpdate = (callback: (angel: Angel) => void) => {
    this.angelUpdateCallback = callback;
  };

  reloadAngel = async (angelId: string) => {
    const angel = await this.getAngel(angelId);
    this.angelUpdateCallback(angel);
  };

  getUser = async (): Promise<User> => {
    const r = await this.axios.get<User>('/users/@me');
    return r.data;
  };

  deleteUser = async (): Promise<void> => {
    await this.axios.delete('/users/@me');
  };

  getAngels = async (): Promise<AngelInfo[]> => {
    const r = await this.axios.get<AngelInfo[]>('/angels');
    return r.data;
  };

  getAngel = async (id: string): Promise<Angel> => {
    const r = await this.axios.get<Angel>(`/angels/${id}`);
    return r.data;
  };

  patchAngel = async (id: string, patch: AngelPatch): Promise<Angel> => {
    const r = await this.axios.patch<AngelPatch, AxiosResponse<Angel>>(`/angels/${id}`, patch);
    this.angelUpdateCallback(r.data);
    return r.data;
  };

  getClients = async (id: string): Promise<Client[]> => {
    const r = await this.axios.get<Client[]>(`/clients?angel=${id}`);
    return r.data;
  };

  getProfiles = async (id: string): Promise<Profile[]> => {
    const r = await this.axios.get<Profile[]>(`/profiles?angel=${id}`);
    return r.data;
  };

  getProfileDetails = async (id: string, profileShort: string): Promise<ProfileDetails> => {
    const r = await this.axios.get<ProfileDetails>(`/profiles/${profileShort}?angel=${id}`);
    return r.data;
  };

  deleteProfile = async (id: string, profileShort: string): Promise<void> => {
    await this.axios.delete(`/profiles/${profileShort}?angel=${id}`);
    await this.reloadAngel(id);
  };

  updateProfile = async (
    id: string,
    profileShort: string,
    profile: ProfileUpdate
  ): Promise<ProfileDetails> => {
    const r = await this.axios.put<ProfileUpdate, AxiosResponse<ProfileDetails>>(
      `/profiles/${profileShort}?angel=${id}`,
      profile
    );
    await this.reloadAngel(id);
    return r.data;
  };

  createProfile = async (id: string, profile: ProfileUpdate): Promise<ProfileDetails> => {
    const r = await this.axios.post<ProfileUpdate, AxiosResponse<ProfileDetails>>(
      `/profiles?angel=${id}`,
      profile
    );
    await this.reloadAngel(id);
    return r.data;
  };

  getWhitelist = async (id: string): Promise<Domain[]> => {
    const r = await this.axios.get<Domain[]>(`/lists/whitelist?angel=${id}`);
    return r.data;
  };

  getBlacklist = async (id: string): Promise<Domain[]> => {
    const r = await this.axios.get<Domain[]>(`/lists/blacklist?angel=${id}`);
    return r.data;
  };

  deleteWhitelist = async (id: string, domain: Domain): Promise<void> => {
    await this.axios.delete(`/lists/whitelist?angel=${id}&domain=${domain}`);
    await this.reloadAngel(id);
  };

  deleteBlacklist = async (id: string, domain: Domain): Promise<void> => {
    await this.axios.delete(`/lists/blacklist?angel=${id}&domain=${domain}`);
  };

  deleteAuthRequest = async (id: string, authRequestDomain: Domain): Promise<void> => {
    await this.axios.delete(`/lists/requests?angel=${id}&request=${authRequestDomain}`);
    await this.reloadAngel(id);
  };

  addWhitelist = async (id: string, domain: Domain, authRequestDomain?: Domain): Promise<void> => {
    await this.axios.post<Domain>(
      `/lists/whitelist?angel=${id}&domain=${domain}${
        authRequestDomain ? `&request=${authRequestDomain}` : ''
      }`
    );
    await this.reloadAngel(id);
  };

  addBlacklist = async (id: string, domain: Domain): Promise<void> => {
    await this.axios.post<Domain>(`/lists/blacklist?angel=${id}&domain=${domain}`);
    await this.reloadAngel(id);
  };

  getAuthRequests = async (id: string): Promise<AuthRequest[]> => {
    const r = await this.axios.get<AuthRequest[]>(`/lists/requests?angel=${id}`);
    return r.data;
  };

  addClient = async (id: string, client: Client): Promise<Client> => {
    const r = await this.axios.post<Client>(`/clients?angel=${id}`, client);
    await this.reloadAngel(id);
    return r.data;
  };

  updateClient = async (id: string, mac: string, client: Client): Promise<Client> => {
    const r = await this.axios.put<Client>(`/clients?angel=${id}&mac=${mac}`, client);
    await this.reloadAngel(id);
    return r.data;
  };

  deletePin = async (id: string, pin: string): Promise<void> => {
    await this.axios.delete(`/clients?angel=${id}&pin=${pin}`);
    await this.reloadAngel(id);
  };

  deleteMac = async (id: string, mac: string): Promise<void> => {
    await this.axios.delete(`/clients?angel=${id}&mac=${mac}`);
    await this.reloadAngel(id);
  };

  applyConfig = async (id: string): Promise<Angel> => {
    const r = await this.axios.post<void, AxiosResponse<Angel>>(`/angels/${id}/apply`);
    return r.data;
  };

  lookupCategory = async (id: string, domain: string): Promise<Category[]> => {
    const r = await this.axios.post<Category[]>(`/categories/lookup?angel=${id}&domain=${domain}`);
    return r.data;
  };

  getCategories = async (): Promise<CategoryMap> => {
    const r = await this.axios.get<CategoryMap>(`/categories`);
    return r.data;
  };

  getStatsSummary = async (id: string): Promise<StatsSummary> => {
    const r = await this.axios.get<StatsSummary>(`/stats/summary?angel=${id}`);
    return r.data;
  };

  getSocialTime = async (id: string): Promise<Social> => {
    const r = await this.axios.get<{ time: string }>(`/lists/social?angel=${id}`);
    return { time: new Date(r.data.time) };
  };

  setSocialTime = async (id: string, minutes: number): Promise<Social> => {
    const r = await this.axios.put<void, AxiosResponse<{ time: string }>>(
      `/lists/social?angel=${id}&duration=${minutes}`
    );
    await this.reloadAngel(id);
    return { time: new Date(r.data.time) };
  };

  clearSocialTime = async (id: string): Promise<Social> => {
    const r = await this.axios.delete<{ time: string }>(`/lists/social?angel=${id}`);
    await this.reloadAngel(id);
    return { time: new Date(r.data.time) };
  };
}
