import React from 'react';

import Loader from './loader';
import { FirebaseContextProvider } from './firebase';
import { AuthContextProvider } from './auth';
import { ApiContextProvider } from './api';
import { SelectionProvider } from './selection';
import { LangProvider } from './lang';
import { PermissionProvider } from './permission';

interface Props {
  children: React.ReactElement;
}

const Context = ({ children }: Props): React.ReactElement => (
  <FirebaseContextProvider loader={<Loader />}>
    <AuthContextProvider loader={<Loader />}>
      <ApiContextProvider loader={<Loader />}>
        <PermissionProvider loader={<Loader />}>
          <SelectionProvider loader={<Loader />}>
            <LangProvider>{children}</LangProvider>
          </SelectionProvider>
        </PermissionProvider>
      </ApiContextProvider>
    </AuthContextProvider>
  </FirebaseContextProvider>
);

export default Context;
