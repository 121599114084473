import Pill from 'components/pill';
import React from 'react';

import styles from './styles.css';

export interface Props {
  title: string;
  pill: number;
}

const Heading = ({ title, pill }: Props): React.ReactElement => (
  <div className={styles.heading}>
    {title}
    {pill > 0 && <Pill value={pill} />}
  </div>
);

export default Heading;
