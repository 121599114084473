import { useApi } from 'context/api';
import { useAuth } from 'context/auth';
import React, { createContext, useContext, useState, useEffect } from 'react';

export enum Permission {
  NOTHING = 0,
  BLOCK_LIST = 102,
  ALLOW_LIST = 102,
}

type PermissionCheck = {
  hasPermission: (permission: Permission) => boolean;
};

export const PermissionContext = createContext<PermissionCheck>({
  hasPermission: () => false,
});

export interface Props {
  children: React.ReactNode;
  loader: React.ReactElement;
}

export const PermissionProvider = ({ children, loader }: Props): React.ReactElement => {
  const { isLoggedIn } = useAuth();
  const api = useApi();
  const [permission, setPermission] = useState<number | null>(null);

  useEffect(() => {
    if (isLoggedIn) {
      api
        .getUser()
        .then((u) => {
          setPermission(u.permission);
        })
        .catch(() => {
          /* FIXME */
        });
    } else {
      setPermission(null);
    }
  }, [isLoggedIn, api]);

  const hasPermission = (requiredPermission: Permission) => {
    if (permission && permission >= requiredPermission) {
      return true;
    }
    return false;
  };

  if (isLoggedIn && permission === null) {
    return loader;
  }

  return (
    <PermissionContext.Provider
      value={{
        hasPermission,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermission = (): PermissionCheck => useContext(PermissionContext);
