import React, { useEffect, useState } from 'react';
import { Modal, SingleSelect, Spacer, TextField, Banner } from '@osag/pyrene';

import { useIntl } from 'react-intl';
import { Client, Profile } from 'context/api/types';
import { useApi } from 'context/api';
import { useSelection } from 'context/selection';
import { useLang } from 'context/lang';
import Scan from './scan';
import { useFirebase } from 'context/firebase';

export interface Props {
  onClose: () => void;
  client?: Client & { mac: string };
}

const Register = ({ onClose, client }: Props): React.ReactElement => {
  const intl = useIntl();
  const api = useApi();
  const { language } = useLang();
  const { logEvent } = useFirebase();

  const [pin, setPin] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [profile, setProfile] = useState<string | null>(null);
  const [nameError, setNameError] = useState<boolean>();
  const [profileError, setProfileError] = useState<boolean>();
  const [submitError, setSubmitError] = useState<boolean>();
  const { angel } = useSelection();
  const [profiles, setProfiles] = useState<Profile[] | null>(null);
  const [profilesLoading, setProfilesLoading] = useState<boolean>(true);

  useEffect(() => {
    setName(client?.name || client?.possiblename || '');
    setProfile(client?.profile || null);
  }, [client]);

  useEffect(() => {
    if (angel?.id) {
      setProfilesLoading(true);
      api
        .getProfiles(angel?.id)
        .then((p) => {
          setProfiles(p.sort((a, b) => a.short.localeCompare(b.short)));
          setProfilesLoading(false);
        })
        .catch(() => {
          /* FIXME */
        });
    }
  }, [angel?.id, api]);

  const onSubmit = async () => {
    if (!angel?.id) {
      return;
    }

    if (!profile) {
      setProfileError(true);
      return;
    }

    setSubmitting(true);
    try {
      if (client?.mac) {
        logEvent('Config:Clients:Update', { profile, name, mac: client.mac, angelId: angel.id });
        await api.updateClient(angel?.id, client.mac, { name, profile });
        onCancel();
      } else {
        logEvent('Config:Clients:Add', { profile, name, angelId: angel.id });
        const d = await api.addClient(angel?.id, { name, profile });
        setPin(d.pin || null);
      }
    } catch (e) {
      setSubmitError(true);
    } finally {
      setSubmitting(false);
    }
  };

  const onCancel = () => {
    setSubmitting(false);
    setName('');
    setSubmitError(false);
    setPin(null);
    onClose();
  };

  const updateName = (v: string) => {
    setName(v);
    setNameError(!v.match(/^[A-Za-z0-9.\- ]+$/));
  };

  if (pin) {
    return <Scan pin={pin} onClose={onCancel} />;
  }

  return (
    <Modal
      rightButtonBarElements={[
        {
          type: 'secondary',
          label: intl.formatMessage({ id: 'config.register.action.cancel' }),
          action: () => {
            onCancel();
          },
        },
        {
          type: 'primary',
          label: intl.formatMessage({
            id: client?.name ? 'config.register.action.edit' : 'config.register.action.register',
          }),
          disabled: nameError || name === '' || profileError || profile === null || submitError,
          action: () => onSubmit(),
        },
      ]}
      renderCallback={() =>
        submitError ? (
          <Banner type="error" label={intl.formatMessage({ id: 'config.register.error.submit' })} />
        ) : (
          <>
            {client?.ip && (
              <>
                <b>{intl.formatMessage({ id: 'config.register.client.ip' })}: </b>
                {client.ip}
                <Spacer size="small" />
              </>
            )}
            {client?.mac && (
              <>
                <b>{intl.formatMessage({ id: 'config.register.client.mac' })}: </b>
                {client.mac}
                <Spacer size="small" />
              </>
            )}
            <SingleSelect
              title={intl.formatMessage({ id: 'config.register.label.profile' })}
              options={profiles?.map((p) => ({
                label: p[`name_${language}` as 'name_de' | 'name_en'],
                value: p.short,
              }))}
              value={
                profile
                  ? {
                      value: profile,
                      label:
                        profiles
                          ?.filter((p) => p.short == profile)
                          ?.map((p) => p[`name_${language}` as 'name_de' | 'name_en'])
                          ?.find(() => true) || '',
                    }
                  : undefined
              }
              onChange={(v) => setProfile(v?.value || null)}
              invalid={profileError}
              invalidLabel={intl.formatMessage({ id: 'config.register.error.profile' })}
            />
            <Spacer size="medium" />
            <TextField
              title={intl.formatMessage({ id: 'config.register.label.name' })}
              value={name}
              invalid={nameError}
              invalidLabel={intl.formatMessage({ id: 'config.register.error.name' })}
              onChange={(v) => updateName(v)}
            />
            <Spacer size="xxxlarge" />
          </>
        )
      }
      loading={submitting || profilesLoading}
      size="small"
      title={intl.formatMessage({ id: 'config.register.title' })}
      onClose={() => onCancel()}
    />
  );
};

export default Register;
