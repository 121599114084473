import React, { useEffect, useState } from 'react';
import { LabelAndValue, Loader } from '@osag/pyrene';
import Card from 'components/card';

import { useIntl } from 'react-intl';
import { useApi } from 'context/api';
import { StatsSummary } from 'context/api/types';
import { useSelection } from 'context/selection';
import styles from './summary.css';

const Summary = (): React.ReactElement => {
  const intl = useIntl();
  const api = useApi();
  const [statsSummary, setStatsSummary] = useState<StatsSummary | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { angel } = useSelection();

  useEffect(() => {
    if (angel?.id) {
      setLoading(true);
      api
        .getStatsSummary(angel?.id)
        .then((s) => {
          setStatsSummary(s);
          setLoading(false);
        })
        .catch(() => {
          /* FIXME */
        });
    }
  }, [angel?.id, api]);

  return (
    <Card fullWidth>
      <>
        <div className={styles.wrapper}>
          <div>
            {loading ? (
              <Loader type="inline" />
            ) : (
              <LabelAndValue
                size="large"
                label={intl.formatMessage({ id: 'activity.summary.total' })}
                value={`${statsSummary?.total}` || '0'}
              />
            )}
          </div>
          <div>
            {loading ? (
              <Loader type="inline" />
            ) : (
              <LabelAndValue
                size="large"
                label={intl.formatMessage({ id: 'activity.summary.malicious' })}
                value={`${statsSummary?.malicious}` || '0'}
              />
            )}
          </div>
        </div>
      </>
    </Card>
  );
};

export default Summary;
