import React, { useEffect, useState } from 'react';
import {
  Banner,
  Heading,
  KeyValueTable,
  Link as PyreneLink,
  Loader,
  Modal,
  Spacer,
} from '@osag/pyrene';
import Card from 'components/card';

import { useIntl } from 'react-intl';
import { useApi } from 'context/api';
import { Profile } from 'context/api/types';
import { useSelection } from 'context/selection';
import { useLang } from 'context/lang';
import { useFirebase } from 'context/firebase';
import ProfileDetailModal from './profiledetailmodal';
import styles from './profile.css';

const ProfileView = (): React.ReactElement => {
  const { language } = useLang();
  const intl = useIntl();
  const api = useApi();
  const [profiles, setProfiles] = useState<Profile[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [updating, setUpdating] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [detailId, setDetailId] = useState<string | null>(null);
  const { angel, updateAngel } = useSelection();
  const { logEvent } = useFirebase();

  const updateDefault = async (profileShort: string) => {
    if (angel?.id) {
      logEvent('Config:Profile:Default', { profile: profileShort, angelId: angel.id });
      setUpdating(true);
      await updateAngel({ default_profile: profileShort }); // eslint-disable-line camelcase
      setUpdating(false);
    }
  };

  const onClose = async () => {
    if (angel?.id) {
      setDetailId(null);
      const p = await api.getProfiles(angel?.id);
      setProfiles(p.sort((a, b) => a.short.localeCompare(b.short)));
    }
  };
  const deleteProfile = async (profileShort: string) => {
    if (angel?.id) {
      logEvent('Config:Profile:Delete', { profile: profileShort, angelId: angel.id });
      try {
        await api.deleteProfile(angel.id, profileShort);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (e.isAxiosError && e?.response?.data?.error) {
          setError(e?.response?.data?.error);
        }
      }
      const p = await api.getProfiles(angel?.id);
      setProfiles(p.sort((a, b) => a.short.localeCompare(b.short)));
    }
  };

  useEffect(() => {
    if (angel?.id) {
      setLoading(true);
      api
        .getProfiles(angel?.id)
        .then((p) => {
          setProfiles(p.sort((a, b) => a.short.localeCompare(b.short)));
          setLoading(false);
        })
        .catch(() => {
          /* FIXME */
        });
    }
  }, [angel?.id, api]);

  return (
    <Card>
      <>
        {error === 'used' && (
          <Modal
            size="small"
            renderFooter={false}
            title={intl.formatMessage({ id: 'config.profile.error' })}
            onClose={() => setError(null)}
            renderCallback={() => (
              <Banner
                styling="inline"
                type="error"
                label={intl.formatMessage({ id: 'config.profile.error.used' })}
              />
            )}
          />
        )}
        <Heading level={3}>{intl.formatMessage({ id: 'config.profiles.title' })}</Heading>
        {detailId !== null && (
          <ProfileDetailModal profileShort={detailId} onClose={() => onClose()} />
        )}
        {!loading ? (
          <>
            <KeyValueTable
              keyWidth={96}
              rows={
                (angel &&
                  profiles?.map((p) => ({
                    key: p[`name_${language}` as 'name_de' | 'name_en'],
                    value: (
                      <>
                        {p.short === angel?.default_profile && (
                          <>
                            {updating ? (
                              <Loader type="inline" />
                            ) : (
                              <span className={styles.default}>
                                {intl.formatMessage({ id: 'config.profiles.default.message' })}
                              </span>
                            )}
                            <br />
                            <br />
                          </>
                        )}
                        {p[`desc_${language}` as 'desc_de' | 'desc_en']}
                        <br />
                        <br />
                        {p.short !== angel?.default_profile && (
                          <>
                            <PyreneLink
                              onClick={() => updateDefault(p.short)}
                              label={intl.formatMessage({ id: 'config.profiles.default.action' })}
                              type="inline"
                            />
                            {' | '}
                          </>
                        )}
                        {p.custom && (
                          <>
                            <PyreneLink
                              onClick={() => deleteProfile(p.short)}
                              label={intl.formatMessage({ id: 'config.profiles.delete' })}
                              type="inline"
                            />
                            {' | '}
                          </>
                        )}
                        <PyreneLink
                          onClick={() => setDetailId(p.short)}
                          label={intl.formatMessage({
                            id: `config.profiles.${p.custom ? 'edit' : 'view'}`,
                          })}
                          type="inline"
                        />
                      </>
                    ),
                  }))) ||
                []
              }
            />{' '}
            <Spacer size="medium" />
            <PyreneLink
              onClick={() => setDetailId('')}
              label={intl.formatMessage({
                id: 'config.profiles.add',
              })}
              type="inline"
            />
          </>
        ) : (
          <Loader type="inline" />
        )}
      </>
    </Card>
  );
};

export default ProfileView;
