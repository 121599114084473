import React from 'react';

import styles from './styles.css';

export interface Props {
  value: number;
}

const Pill = ({ value }: Props): React.ReactElement => (
  <span className={styles.notification}>{value}</span>
);

export default Pill;
