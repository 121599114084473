import React, { useEffect, useState } from 'react';

import { Link as PyreneLink, Heading } from '@osag/pyrene';

import { useAuth } from 'context/auth';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const Signin = (): React.ReactElement => {
  const intl = useIntl();

  const { signout } = useAuth();
  const navigate = useNavigate();

  const [signedOut, setSignedOut] = useState<boolean>(false);

  useEffect(() => {
    signout()
      .then(() => {
        setSignedOut(true);
      })
      .catch(() => {
        //
      });
  }, [signout]);

  if (!signedOut) {
    return <span />;
  }

  return (
    <>
      <Heading level={3}>{intl.formatMessage({ id: 'auth.signout.title' })}</Heading>
      <PyreneLink
        onClick={() => navigate('/auth/signin')}
        label={intl.formatMessage({ id: 'auth.signout.signin' })}
      />
    </>
  );
};

export default Signin;
