import classNames from 'classnames';
import { useAuth } from 'context/auth';

import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './header.css';
import { NAVIGATION_LINKS } from '../index';
import Selection from './selection';
import { useSelection } from 'context/selection';
import Pill from 'components/pill';

const Header = (): React.ReactElement => {
  const { isLoggedIn } = useAuth();
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const { angel } = useSelection();

  const goto = (link: { path: string }) => {
    navigate(link.path);
  };

  return (
    <div className={classNames(styles.header, { [styles.headerHasNav]: isLoggedIn })}>
      <div className={styles.logo}>
        <div
          className={styles.logoImg}
          role="button"
          aria-label="home"
          tabIndex={0}
          onClick={() => {
            navigate('/');
          }}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              navigate('/');
            }
          }}
        />
        <div className={styles.selector}>
          <Selection />
        </div>
      </div>
      {isLoggedIn && (
        <div className={styles.navigation}>
          {NAVIGATION_LINKS.map((l) => (
            <div
              role="button"
              tabIndex={0}
              key={l.key}
              onClick={() => {
                goto(l);
              }}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  goto(l);
                }
              }}
              className={classNames(styles.link, {
                [styles.linkActive]: location.pathname.startsWith(l.path),
              })}
            >
              {intl.formatMessage({ id: `navigation.${l.key}` })}
              {l.key === 'status' && angel && angel?.requests > 0 && (
                <Pill value={angel.requests} />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Header;
