import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { useIntl } from 'react-intl';

import { useAuth, isAuthError } from 'context/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { Banner, Heading } from '@osag/pyrene';

const errorCodeToKey: Record<string, string> = {
  'auth/invalid-custom-token': 'auth.common.error.invalidtoken',
};

const Token = (): React.ReactElement => {
  const intl = useIntl();

  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  const { tokenSignin } = useAuth();

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (params.token) {
      tokenSignin(params.token as string)
        .then(() => {
          const goto = (params.goto as string) || '/';
          navigate(goto);
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
          if (isAuthError(e)) {
            setError(e.code);
          }
        });
    }
  }, [navigate, location.search, tokenSignin]);

  if (!error) {
    return <span />;
  }

  return (
    <>
      <Heading level={3}>{intl.formatMessage({ id: 'auth.signin.title' })}</Heading>
      <Banner type="error" label={intl.formatMessage({ id: 'auth.signin.error.title' })}>
        {intl.formatMessage({ id: errorCodeToKey[error] || 'auth.signin.error.generic' })}
      </Banner>
    </>
  );
};

export default Token;
