import React, { useEffect, useState } from 'react';
import {
  Button,
  Spacer,
  Loader,
  Heading,
  KeyValueTable,
  Link as PyreneLink,
  Collapsible,
} from '@osag/pyrene';
import Card from 'components/card';

import { useAuth } from 'context/auth';
import { useIntl } from 'react-intl';
import { useApi } from 'context/api';
import { User } from 'context/api/types';
import { useNavigate } from 'react-router-dom';

const Profile = (): React.ReactElement => {
  const intl = useIntl();
  const api = useApi();
  const { signout } = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    api
      .getUser()
      .then((u) => {
        setUser(u);
      })
      .catch(() => {
        /* FIXME */
      });
  }, [api]);

  const onDelete = async () => {
    if (!window.confirm(intl.formatMessage({ id: 'auth.profile.delete.confirm' }))) {
      return;
    }

    try {
      await api.deleteUser();
      signout().catch(() => {
        /* FIXME */
      });
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
    }
  };

  return (
    <>
      <Card>
        <>
          <Heading level={3}>{intl.formatMessage({ id: 'auth.profile.title' })}</Heading>
          <div style={{ maxWidth: '480px' }}>
            <KeyValueTable
              keyWidth={64}
              rows={[
                {
                  key: intl.formatMessage({ id: 'auth.common.name' }),
                  value: user ? (
                    `${user?.firstname || ''} ${user?.lastname || ''}`
                  ) : (
                    <Loader type="inline" />
                  ),
                },
                {
                  key: intl.formatMessage({ id: 'auth.common.email' }),
                  value: user ? (
                    <span data-testid="user-email">{user?.email}</span>
                  ) : (
                    <Loader type="inline" />
                  ),
                },
              ]}
            />
          </div>
          <Spacer size="large" />
          <PyreneLink
            onClick={() => navigate('/auth/signout')}
            label={intl.formatMessage({ id: 'auth.profile.signout' })}
          />
          <Spacer size="small" />
        </>
      </Card>
      <Card>
        <>
          <PyreneLink
            path="https://sighub.io/actoverview"
            label={intl.formatMessage({ id: 'auth.profile.more' })}
          />
        </>
      </Card>
      <div style={{ visibility: 'hidden' }}>
        <Card>
          <Collapsible
            renderCallback={() => (
              <>
                <Spacer size="small" />
                <Button
                  type="danger"
                  icon="trash"
                  onClick={() => onDelete()}
                  label={intl.formatMessage({ id: 'auth.profile.delete' })}
                />
              </>
            )}
            labelCollapsed={intl.formatMessage({ id: 'auth.profile.danger' })}
            labelExpanded={intl.formatMessage({ id: 'auth.profile.danger' })}
          />
        </Card>
      </div>
    </>
  );
};

export default Profile;
