import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { useAuth } from 'context/auth';

import Signup from './signup';
import Signin from './signin';
import Token from './token';
import Forgot from './forgot';
import Signout from './signout';

const Root = (): React.ReactElement => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  return (
    <Routes>
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/signin" element={<Signin />} />
      <Route path="/token" element={<Token />} />
      <Route path="/signout" element={<Signout />} />
      {!isLoggedIn && (
        <Route
          path="/"
          element={<Navigate to="/signin" state={{ referrer: location.pathname }} />}
        />
      )}
    </Routes>
  );
};
export default Root;
