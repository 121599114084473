import React from 'react';
import { Heading } from '@osag/pyrene';
import { useIntl } from 'react-intl';

const Error = (): React.ReactElement => {
  const intl = useIntl();

  return (
    <>
      <Heading level={3}>{intl.formatMessage({ id: 'error.notfound' })}</Heading>
    </>
  );
};

export default Error;
