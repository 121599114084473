import React, { createContext, useContext, useState } from 'react';
import { IntlProvider, MessageFormatElement } from 'react-intl';

import en from '../../../lang/en.json';
import de from '../../../lang/de.json';

// Explicitly setting type since we're importing json.
const messages: Record<string, Record<string, string> | Record<string, MessageFormatElement[]>> = {
  en,
  de,
};

type AvailableLanguage = {
  code: string;
  description: string;
};

const availableLanguages: AvailableLanguage[] = [
  { code: 'en', description: 'English' },
  { code: 'de', description: 'Deutsch' },
];

const browserLanguage = navigator.language.split(/[-_]/)[0];
const localPreference = localStorage.getItem('language');

const getDefaultLanguage = () => {
  if (localPreference !== null && typeof messages[localPreference] !== 'undefined') {
    return localPreference;
  }
  if (typeof messages[browserLanguage] !== 'undefined') {
    return browserLanguage;
  }

  return 'en';
};

type Lang = {
  language: string;
  selectLanguage: (lang: string) => void;
  availableLanguages: AvailableLanguage[];
};

export const LangContext = createContext<Lang>({
  language: getDefaultLanguage(),
  selectLanguage: () => {
    /* default */
  },
  availableLanguages,
});

export interface Props {
  children: React.ReactNode;
}

export const LangProvider = ({ children }: Props): React.ReactElement => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>(getDefaultLanguage());

  const selectLanguage = (lang: string) => {
    if (typeof messages[lang] === 'undefined') {
      throw new Error(`Language ${lang} is not supported!`);
    }

    setSelectedLanguage(lang);
    localStorage.setItem('language', lang);
  };

  return (
    <LangContext.Provider
      value={{
        language: selectedLanguage,
        selectLanguage,
        availableLanguages,
      }}
    >
      <IntlProvider messages={messages[selectedLanguage]} locale={selectedLanguage}>
        {children}
      </IntlProvider>
    </LangContext.Provider>
  );
};

export const useLang = (): Lang => useContext(LangContext);
