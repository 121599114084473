import { Loader } from '@osag/pyrene';
import React from 'react';

import styles from './loader.css';

const PageLoader = (): React.ReactElement => (
  <div className={styles.all}>
    <Loader size="large" />
  </div>
);

export default PageLoader;
