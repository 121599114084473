import React from 'react';
import classNames from 'classnames';

import styles from './styles.css';

export interface Props {
  children: React.ReactElement;
  fullWidth?: boolean;
}

const Card = ({ children, fullWidth = false }: Props): React.ReactElement => (
  <div className={classNames(styles.cardWrapper, { [styles.cardWrapperFull]: fullWidth })}>
    <div>{children}</div>
  </div>
);

export default Card;
