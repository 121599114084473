import React from 'react';

import { Heading, Link, Loader, Spacer } from '@osag/pyrene';
import Card from 'components/card';

import { faEye, faEyeSlash, faBiohazard, faCheckSquare } from '@fortawesome/free-solid-svg-icons';

import StatusIcon from 'components/statusIcon/statusIcon';
import { useIntl } from 'react-intl';
import { useSelection } from 'context/selection';

const NetworkStatusView = (): React.ReactElement => {
  const intl = useIntl();
  const { angel } = useSelection();

  if (!angel) {
    return (
      <Card>
        <>
          <Heading level={3}>{intl.formatMessage({ id: 'status.network.title' })}</Heading>
          <Spacer size="small" />
          <Loader type="inline" />
        </>
      </Card>
    );
  }

  if (!angel.status.connected) {
    return (
      <Card>
        <>
          <Heading level={3}>{intl.formatMessage({ id: 'status.network.title' })}</Heading>
          <StatusIcon
            title={intl.formatMessage({
              id: 'status.network.disconnected.title',
            })}
            message={intl.formatMessage({
              id: 'status.network.disconnected.message',
            })}
            icon={faEyeSlash}
          />
        </>
      </Card>
    );
  }

  return (
    <Card>
      <>
        <Heading level={3}>{intl.formatMessage({ id: 'status.network.title' })}</Heading>
        {angel.traffic.any ? (
          <>
            <StatusIcon
              title={intl.formatMessage({ id: 'status.network.active.title' })}
              message={intl.formatMessage({ id: 'status.network.active.message' })}
              icon={faEye}
            />
            {angel.traffic.malicious ? (
              <StatusIcon
                title={intl.formatMessage({ id: 'status.network.malicious.title' })}
                message={intl.formatMessage(
                  { id: 'status.network.malicious.message' },
                  {
                    link: ([t]) => <Link type="inline" path="/activity" label={t} />,
                  }
                )}
                icon={faBiohazard}
              />
            ) : (
              <StatusIcon
                title={intl.formatMessage({ id: 'status.network.nomalicious.title' })}
                message={intl.formatMessage({ id: 'status.network.nomalicious.message' })}
                icon={faCheckSquare}
              />
            )}
          </>
        ) : (
          <StatusIcon
            title={intl.formatMessage({ id: 'status.network.inactive.title' })}
            message={intl.formatMessage({ id: 'status.network.inactive.message' })}
            icon={faEyeSlash}
          />
        )}
      </>
    </Card>
  );
};

export default NetworkStatusView;
