import React from 'react';
import { Link as PyreneLink } from '@osag/pyrene';
import Card from 'components/card';

import { useIntl } from 'react-intl';
import { useSelection } from 'context/selection';
import Summary from './summary';
import Apply from 'app/config/apply';

const Profile = (): React.ReactElement => {
  const intl = useIntl();
  const { availableAngels } = useSelection();

  if (!availableAngels?.length) {
    return (
      <Card>
        <>
          {intl.formatMessage(
            { id: 'status.install.noangel.message' },
            {
              link: ([t]) => <PyreneLink type="inline" path="https://sighub.io/home" label={t} />,
            }
          )}
        </>
      </Card>
    );
  }
  return (
    <>
      <Apply />
      <Summary />
      <Card>
        <>
          <PyreneLink
            path="https://sighub.io/dashboard"
            label={intl.formatMessage({ id: 'activity.more' })}
          />
        </>
      </Card>
    </>
  );
};

export default Profile;
