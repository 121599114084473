import React, { useState } from 'react';
import { Link as PyreneLink, Loader } from '@osag/pyrene';

import { useIntl } from 'react-intl';
import { Client } from 'context/api/types';
import { useApi } from 'context/api';
import { useSelection } from 'context/selection';
import { useFirebase } from 'context/firebase';

export interface Props {
  client: Client;
  setDisplayPin: (pin: string) => void;
  onClientUpdate: () => void;
  setEditClient: (client: Client & { mac: string }) => void;
}

const ClientLinks = ({
  client,
  setDisplayPin,
  onClientUpdate,
  setEditClient,
}: Props): React.ReactElement => {
  const intl = useIntl();
  const { angel } = useSelection();
  const { logEvent } = useFirebase();
  const { deletePin, deleteMac } = useApi();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const onDeletePin = async (pin: string) => {
    if (angel?.id) {
      logEvent('Config:Clients:Delete:Pin', { pin, angelId: angel.id });
      setIsDeleting(true);
      await deletePin(angel.id, pin);
      onClientUpdate();
    }
  };

  const onDeleteMac = async (mac: string) => {
    if (angel?.id) {
      logEvent('Config:Clients:Delete:Mac', { mac, angelId: angel.id });
      setIsDeleting(true);
      await deleteMac(angel.id, mac);
      onClientUpdate();
    }
  };

  const { name, possiblename, pin, mac, ip, profile } = client;

  if (mac && name) {
    return (
      <>
        {isDeleting ? (
          <Loader type="inline" />
        ) : (
          <PyreneLink
            type="inline"
            label={intl.formatMessage({ id: 'config.clients.links.unregister' })}
            onClick={() => onDeleteMac(mac)}
          />
        )}
        {' | '}
        <PyreneLink
          type="inline"
          label={intl.formatMessage({ id: 'config.clients.links.edit' })}
          onClick={() => setEditClient({ mac, name, ip, profile })}
        />
      </>
    );
  }

  if (mac) {
    return (
      <>
        <PyreneLink
          type="inline"
          label={intl.formatMessage({ id: 'config.clients.links.register' })}
          onClick={() => setEditClient({ mac, name, possiblename, ip })}
        />
      </>
    );
  }

  if (pin && name) {
    return (
      <>
        <PyreneLink
          type="inline"
          label={intl.formatMessage({ id: 'config.clients.links.link' })}
          onClick={() => setDisplayPin(pin)}
        />
        {' | '}
        {isDeleting ? (
          <Loader type="inline" />
        ) : (
          <PyreneLink
            type="inline"
            label={intl.formatMessage({ id: 'config.clients.links.unregister' })}
            onClick={() => onDeletePin(pin)}
          />
        )}
      </>
    );
  }

  return <></>;
};

export default ClientLinks;
