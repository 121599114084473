import React, { useState } from 'react';
import { Button, TextField, Spacer, Link as PyreneLink, Heading } from '@osag/pyrene';

import { useAuth } from 'context/auth';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const Forgot = (): React.ReactElement => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { forgot } = useAuth();

  const [email, setEmail] = useState('');

  const onSubmit = () => {
    forgot(email).catch(() => {
      /* FIXME */
    });
  };

  return (
    <>
      <Heading level={3}>{intl.formatMessage({ id: 'auth.forgot.title' })}</Heading>
      <TextField
        title={intl.formatMessage({ id: 'auth.common.email' })}
        name="email"
        value={email}
        onChange={(v) => setEmail(v)}
      />
      <Spacer size="small" />
      <Button
        type="primary"
        onClick={onSubmit}
        label={intl.formatMessage({ id: 'auth.forgot.submit' })}
      />
      <Spacer size="medium" />
      <PyreneLink
        onClick={() => navigate('/auth/signin')}
        label={intl.formatMessage({ id: 'auth.forgot.signin' })}
      />
    </>
  );
};

export default Forgot;
