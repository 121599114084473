import React, { useEffect, useState } from 'react';
import { Heading, Link as PyreneLink, Loader, SimpleTable, Spacer } from '@osag/pyrene';
import Card from 'components/card';

import { useIntl } from 'react-intl';
import { useApi } from 'context/api';
import { Client, Profile } from 'context/api/types';
import { useSelection } from 'context/selection';
import { useLang } from 'context/lang';
import Register from './register';
import Scan from './scan';
import ClientLinks from './clientlinks';
import styles from './clients.css';

const clientSorter = (a: Client, b: Client): number => {
  if (a.name && !b.name) {
    return -1;
  }

  if (!a.name && b.name) {
    return 1;
  }

  if (a.name && b.name) {
    return a.name.localeCompare(b.name);
  }

  return a.mac?.localeCompare(b.mac ?? '') ?? 0;
};

const DevicesView = (): React.ReactElement => {
  const intl = useIntl();
  const api = useApi();
  const [clients, setClients] = useState<Client[] | null>(null);
  const [profiles, setProfiles] = useState<Profile[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editClient, setEditClient] = useState<(Client & { mac: string }) | null>(null);
  const [displayPin, setDisplayPin] = useState<string | null>(null);
  const { angel } = useSelection();
  const { language } = useLang();

  useEffect(() => {
    if (angel?.id) {
      setLoading(true);
      api
        .getClients(angel?.id)
        .then((d) => {
          setClients(d.sort(clientSorter));
          setLoading(false);
        })
        .catch(() => {
          /* FIXME */
        });
      api
        .getProfiles(angel?.id)
        .then((p) => {
          setProfiles(p.sort((a, b) => a.short.localeCompare(b.short)));
        })
        .catch(() => {
          /* FIXME */
        });
    }
  }, [angel?.id, api]);

  const onClose = () => {
    setShowModal(false);
    setEditClient(null);
    if (angel?.id) {
      api
        .getClients(angel?.id)
        .then((d) => {
          setClients(d.sort(clientSorter));
        })
        .catch(() => {
          /* FIXME */
        });
    }
  };

  const onClientUpdate = () => {
    if (angel?.id) {
      api
        .getClients(angel?.id)
        .then((d) => {
          setClients(d.sort(clientSorter));
        })
        .catch(() => {
          /* FIXME */
        });
    }
  };

  if (loading) {
    return (
      <Card>
        <>
          <Heading level={3}>{intl.formatMessage({ id: 'config.clients.title' })}</Heading>
          <Loader type="inline" />
        </>
      </Card>
    );
  }

  return (
    <>
      {showModal && <Register onClose={() => onClose()} />}
      {!showModal && editClient && <Register onClose={() => onClose()} client={editClient} />}
      {displayPin && <Scan pin={displayPin} onClose={() => setDisplayPin(null)} />}
      <Card>
        <>
          <Heading level={3}>{intl.formatMessage({ id: 'config.clients.title' })}</Heading>
          {clients?.length ? (
            <SimpleTable
              data={clients || []}
              columns={[
                {
                  headerName: intl.formatMessage({ id: 'config.clients.column.name' }),
                  accessor: 'name',
                  id: 'name',
                  cellRenderCallback: ({ name, possiblename }) => (
                    <span className={name ? styles.registered : styles.unregistered}>
                      {name || possiblename || ''}
                    </span>
                  ),
                },
                {
                  headerName: intl.formatMessage({ id: 'config.clients.column.profile' }),
                  accessor: 'profile',
                  id: 'profile',
                  cellRenderCallback: (row) => (
                    <span className={row.name ? styles.registered : styles.unregistered}>
                      {profiles?.find((p) => p.short === row.profile)?.[
                        `name_${language}` as 'name_en' | 'name_de'
                      ] || ''}
                    </span>
                  ),
                },
                {
                  headerName: intl.formatMessage({ id: 'config.clients.column.ip' }),
                  accessor: 'ip',
                  id: 'ip',
                  cellRenderCallback: ({ name, ip }) => (
                    <span className={name ? styles.registered : styles.unregistered}>
                      {ip || ''}
                    </span>
                  ),
                },
                // {
                //   headerName: intl.formatMessage({ id: 'config.clients.column.mac' }),
                //   accessor: 'mac',
                //   id: 'mac',
                // },
                {
                  id: 'links',
                  accessor: 'name',
                  width: 200,
                  cellRenderCallback: (row) => (
                    <span className={row.name ? styles.registered : styles.unregistered}>
                      <ClientLinks
                        client={row}
                        setDisplayPin={(p) => setDisplayPin(p)}
                        onClientUpdate={() => onClientUpdate()}
                        setEditClient={(c) => setEditClient(c)}
                      />
                    </span>
                  ),
                },
              ]}
            />
          ) : (
            intl.formatMessage({ id: 'config.clients.empty.clients' })
          )}
          <Spacer size="medium" />
          <PyreneLink
            onClick={() => setShowModal(true)}
            label={intl.formatMessage({ id: 'config.clients.register.link' })}
          />
        </>
      </Card>
    </>
  );
};

export default DevicesView;
