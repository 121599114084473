import React from 'react';
import { Link as PyreneLink } from '@osag/pyrene';
import Card from 'components/card';

import { useIntl } from 'react-intl';
import { useSelection } from 'context/selection';
import ClientsView from './clients';
import ProfileView from './profile';
import WebsitesView from './websites';
import Apply from './apply';
import Tester from './tester';
import SocialTimeView from './socialtime';
import RequestsView from './requests';

const Profile = (): React.ReactElement => {
  const intl = useIntl();
  const { availableAngels } = useSelection();

  if (!availableAngels?.length) {
    return (
      <Card>
        <>
          {intl.formatMessage(
            { id: 'status.install.noangel.message' },
            {
              link: ([t]) => <PyreneLink type="inline" path="https://sighub.io/home" label={t} />,
            }
          )}
        </>
      </Card>
    );
  }

  return (
    <>
      <Apply />
      <ClientsView />
      <ProfileView />
      <WebsitesView />
      <RequestsView />
      <Tester />
      <SocialTimeView />
    </>
  );
};

export default Profile;
