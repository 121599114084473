import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter as Router } from 'react-router-dom';

import App from 'app';
import Context from 'context';

import './styles.css';

const Root = (): React.ReactElement => (
  <Router>
    <Context>
      <App />
    </Context>
  </Router>
);

const rootElement = document.createElement('div');
rootElement.setAttribute('data-testid', 'root');

ReactDOM.render(<Root />, document.body.appendChild(rootElement));
