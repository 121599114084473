import React from 'react';
import { Heading, Spacer } from '@osag/pyrene';
import Card from 'components/card';

import { usePermission, Permission } from 'context/permission';
import Whitelist from './whitelist';
import Blacklist from './blacklist';

import { useIntl } from 'react-intl';

const WebsitesView = (): React.ReactElement => {
  const intl = useIntl();
  const { hasPermission } = usePermission();

  return (
    <Card>
      <>
        <Heading level={3}>{intl.formatMessage({ id: 'config.whitelist.title' })}</Heading>
        <Whitelist />
        <Spacer size="medium" />
        {hasPermission(Permission.BLOCK_LIST) && (
          <>
            <Blacklist />
            <Spacer size="medium" />
          </>
        )}
      </>
    </Card>
  );
};

export default WebsitesView;
