import React, { useState } from 'react';
import { Button, TextField, Spacer, Link as PyreneLink, Banner, Heading } from '@osag/pyrene';

import { isAuthError, useAuth } from 'context/auth';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const errorCodeToKey: Record<string, string> = {
  'auth/invalid-email': 'auth.common.error.invalidemail',
  'auth/weak-password': 'auth.signup.error.weakpassword',
  'auth/email-already-in-use': 'auth.signup.error.alreadyused',
};

const Signup = (): React.ReactElement => {
  const intl = useIntl();

  const { signup, signin } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState<string | null>(null);

  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const onSubmit = async () => {
    if (password === password2) {
      try {
        await signup(email, password);
        await signin(email, password);
        navigate('/');
      } catch (e) {
        console.log(e); // eslint-disable-line no-console
        if (isAuthError(e)) {
          setError(e.code);
        }
      }
    } else {
      setIsPasswordValid(false);
    }
  };
  return (
    <>
      <Heading level={3}>{intl.formatMessage({ id: 'auth.signup.title' })}</Heading>
      {error && (
        <>
          <Banner type="error" label={intl.formatMessage({ id: 'auth.signup.error.title' })}>
            {intl.formatMessage({ id: errorCodeToKey[error] || 'auth.signup.error.generic' })}
          </Banner>
          <Spacer size="medium" />
        </>
      )}
      <TextField
        title={intl.formatMessage({ id: 'auth.common.email' })}
        name="email"
        value={email}
        onChange={(v) => setEmail(v)}
      />
      <Spacer size="small" />
      <TextField
        title={intl.formatMessage({ id: 'auth.common.password' })}
        name="password"
        secret
        value={password}
        onChange={(v) => {
          setPassword(v);
          setIsPasswordValid(true);
        }}
        invalid={!isPasswordValid}
      />
      <Spacer size="small" />
      <TextField
        title={intl.formatMessage({ id: 'auth.common.password2' })}
        name="password2"
        secret
        value={password2}
        onChange={(v) => {
          setPassword2(v);
          setIsPasswordValid(true);
        }}
        invalid={!isPasswordValid}
        invalidLabel={intl.formatMessage({ id: 'auth.signup.error.passwordmismatch' })}
      />
      <Spacer size="small" />
      <div style={{ width: 240 }}>
        {intl.formatMessage(
          { id: 'auth.signup.conditions' },
          {
            terms: ([t]) => <PyreneLink type="inline" path="https://sighub.io/terms" label={t} />,
            privacy: ([t]) => (
              <PyreneLink type="inline" path="https://sighub.io/privacy" label={t} />
            ),
          }
        )}
      </div>
      <Spacer size="large" />
      <Button
        type="primary"
        onClick={onSubmit}
        label={intl.formatMessage({ id: 'auth.signup.submit' })}
      />
      <Spacer size="medium" />
      <PyreneLink
        onClick={() => navigate('/auth/signin')}
        label={intl.formatMessage({ id: 'auth.signup.signin' })}
      />
    </>
  );
};

export default Signup;
