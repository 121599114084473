import { useFirebase } from 'context/firebase';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'context/auth';
import Api from './api';

export const ApiContext = createContext<Api>(new Api(''));

export interface Props {
  children: React.ReactNode;
  loader: React.ReactElement;
}

// provided by webpack in dev mode
declare const DEV_API_BASE_URL: string;

export const ApiContextProvider = ({ children, loader }: Props): React.ReactElement => {
  const { getConfig } = useFirebase();
  const { getIdToken, isLoggedIn } = useAuth();

  const apiUrl = DEV_API_BASE_URL || getConfig('api_base_url')?.asString() || '';
  const [apiToken, setApiToken] = useState<string | undefined>();
  const api = useMemo(() => new Api(apiUrl, apiToken), [apiUrl, apiToken]);

  useEffect(() => {
    if (isLoggedIn) {
      getIdToken()
        .then((idToken) => {
          setApiToken(idToken);
        })
        .catch(() => {
          //
        });
    }
  }, [isLoggedIn, getIdToken]);

  if (isLoggedIn && !apiToken) {
    return loader;
  }

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

export const useApi = (): Api => useContext(ApiContext);
