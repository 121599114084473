import Apply from 'app/config/apply';
import SocialTimeView from 'app/config/socialtime';
import RequestsView from 'app/status/requests';
import { useSelection } from 'context/selection';
import React from 'react';
import AngelStatusView from './angel';
import NetworkStatusView from './network';

const Status = (): React.ReactElement => {
  const { availableAngels } = useSelection();

  return (
    <>
      <Apply />
      <AngelStatusView />
      {availableAngels && availableAngels.length > 0 && (
        <>
          <NetworkStatusView />
          <RequestsView />
          <SocialTimeView />
        </>
      )}
    </>
  );
};

export default Status;
