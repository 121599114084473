import React from 'react';
import classNames from 'classnames';

import Footer from './footer';
import Header from './header';
import styles from './styles.css';

export interface Props {
  children: React.ReactNode;
  simple: boolean;
}

const Layout = ({ children, simple = false }: Props): React.ReactElement => (
  <div className={styles.all}>
    <Header />
    <div className={classNames(styles.content, { [styles.contentCenter]: simple })}>
      <div className={classNames(styles.inner, { [styles.innerFull]: !simple })}>{children}</div>
    </div>
    <Footer />
  </div>
);
export default Layout;
