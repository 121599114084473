export const getUrlFromInput = (input: string): URL | null => {
  const trimmed = input.trim();

  // Special handling for spaces, not allowed
  if (trimmed.includes(' ')) {
    return null;
  }

  try {
    return new URL(trimmed.match('^https?://') ? trimmed : `https://${trimmed}`);
  } catch {
    return null;
  }
  return null;
};

export const getHostnameFromInput = (input: string): string | null => {
  return getUrlFromInput(input)?.hostname || null;
};
