import { Link as PyreneLink } from '@osag/pyrene';
import { useLang } from 'context/lang';
import React from 'react';

import styles from './footer.css';

declare const BUILD_VERSION: string;

const Footer = (): React.ReactElement => {
  const { availableLanguages, selectLanguage } = useLang();

  return (
    <div className={styles.footer}>
      <div className={styles.copy}>
        &copy; {new Date().getFullYear()} &nbsp;
        <PyreneLink label="sighub.io" type="inline" target="_new" path="https://sighub.io" />
      </div>
      <div className={styles.build}>{BUILD_VERSION}</div>
      <div className={styles.lang}>
        {availableLanguages.map((lang, i) => (
          <span key={lang.code}>
            <PyreneLink
              type="inline"
              onClick={() => selectLanguage(lang.code)}
              label={lang.description}
            />
            {i < availableLanguages.length - 1 && ' | '}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Footer;
