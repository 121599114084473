import React from 'react';
import { Heading, Spacer } from '@osag/pyrene';
import Card from 'components/card';

import Authlist from './authlist';

import { useIntl } from 'react-intl';

const RequestsView = (): React.ReactElement => {
  const intl = useIntl();

  return (
    <Card>
      <>
        <Heading level={3}>{intl.formatMessage({ id: 'config.whitelist.authlist.title' })}</Heading>
        <Spacer size="medium" />
        <Authlist />
      </>
    </Card>
  );
};

export default RequestsView;
