import React, { ReactNode } from 'react';

import { Heading } from '@osag/pyrene';

import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './statusIcon.css';

export interface Props {
  icon: IconDefinition;
  title: string;
  message: string | ReactNode;
}

const StatusIcon = ({ icon, title, message }: Props): React.ReactElement => (
  <div className={styles.statusIcon}>
    <div className={styles.icon}>
      <FontAwesomeIcon icon={icon} size="3x" />
    </div>
    <div>
      <Heading level={4}>{title}</Heading>
      <div className={styles.message}>{message}</div>
    </div>
  </div>
);

export default StatusIcon;
