import React, { useEffect, useState } from 'react';
import { Button, Spacer } from '@osag/pyrene';
import Card from 'components/card';

import { useIntl } from 'react-intl';
import { useSelection } from 'context/selection';
import { useFirebase } from 'context/firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowCircleDown,
  faCheckCircle,
  faExclamationCircle,
  faPowerOff,
} from '@fortawesome/free-solid-svg-icons';
import styles from './apply.css';

const Apply = (): React.ReactElement => {
  const intl = useIntl();
  const { angel, availableAngels, applyConfig } = useSelection();
  const [applying, setApplying] = useState<boolean>(false);
  const { logEvent } = useFirebase();

  useEffect(() => {
    setApplying(false);
  }, [angel]);

  if (!availableAngels?.length) {
    return <span />;
  }

  if (angel?.status.pending) {
    return (
      <Card fullWidth>
        <>
          <span className={styles.icon}>
            <FontAwesomeIcon icon={faExclamationCircle} />
          </span>
          {intl.formatMessage({ id: 'config.info.pending' })}
          <Spacer size="small" />
          <Button
            loading={applying}
            onClick={() => {
              logEvent('Config:Apply', { location: 'config' });
              applyConfig().catch(() => {
                /* FIXME */
              });
              setApplying(true);
            }}
            label={intl.formatMessage({ id: 'config.info.apply' })}
          />
        </>
      </Card>
    );
  }

  if (angel?.status.locked) {
    return (
      <Card fullWidth>
        <>
          <span className={styles.icon}>
            <FontAwesomeIcon icon={angel.status.connected ? faArrowCircleDown : faPowerOff} />{' '}
          </span>
          {angel.status.connected
            ? intl.formatMessage({ id: 'config.info.applying' })
            : intl.formatMessage({ id: 'config.info.applying.disconnected' })}
        </>
      </Card>
    );
  }

  if (angel?.status.recentConfigUpdate) {
    return (
      <Card fullWidth>
        <>
          <span className={styles.icon}>
            <FontAwesomeIcon icon={faCheckCircle} />
          </span>
          {intl.formatMessage({ id: 'config.info.applied' })}
        </>
      </Card>
    );
  }

  return <span />;
};

export default Apply;
