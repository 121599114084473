import React, { useState } from 'react';
import { Loader, Spacer, Heading, Button, TextField, Badge } from '@osag/pyrene';

import { useIntl } from 'react-intl';
import { useApi } from 'context/api';
import { Category, Domain } from 'context/api/types';
import { useSelection } from 'context/selection';
import { useFirebase } from 'context/firebase';
import { getHostnameFromInput } from 'lib/url';
import { useLang } from 'context/lang';
import Card from 'components/card';
import styles from './tester.css';

const Tester = (): React.ReactElement => {
  const intl = useIntl();
  const { language } = useLang();
  const api = useApi();
  const { logEvent } = useFirebase();

  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<Category[] | null>(null);
  const [inputDomain, setInputDomain] = useState<string>('');
  const [hostname, setHostname] = useState<string | null>(null);
  const { angel } = useSelection();

  const onDomainUpdate = (d: string) => {
    setInputDomain(d);
    setCategories(null);
    const hostname = getHostnameFromInput(d);
    setHostname(hostname);
  };

  const onSubmit = (domain: Domain) => {
    if (angel?.id && hostname) {
      setLoading(true);
      api
        .lookupCategory(angel?.id, hostname)
        .then((r) => {
          setCategories(r);
          setLoading(false);
        })
        .catch(() => {
          /* FIXME */
        });
      logEvent('Config:Domain:Lookup', { domain });
    }
  };

  if (!angel) {
    return (
      <Card>
        <>
          <Heading level={3}>{intl.formatMessage({ id: 'config.tester.title' })}</Heading>
          <Loader type="inline" />
        </>
      </Card>
    );
  }

  return (
    <Card>
      <>
        <Heading level={3}>{intl.formatMessage({ id: 'config.tester.title' })}</Heading>
        <div className={styles.inputAndButton}>
          <div className={styles.input}>
            <TextField
              title={intl.formatMessage({
                id: `config.tester.domain`,
              })}
              value={inputDomain}
              invalid={!!inputDomain && !hostname}
              onChange={(v: string) => onDomainUpdate(v)}
            />
          </div>
          <div className={styles.button}>
            <Button
              label={intl.formatMessage({ id: 'config.tester.submit' })}
              onClick={() => onSubmit(inputDomain)}
              disabled={!hostname}
              loading={loading}
            />
          </div>
        </div>
        <Spacer size="small" />
        {categories && (
          <>
            <Spacer size="small" />
            {intl.formatMessage({ id: 'config.tester.categories' })}
            <div className={styles.tags}>
              {categories.map((c, i) => (
                <Badge
                  key={c.name_en}
                  maxWidth={200}
                  label={c[`name_${language}` as 'name_en' | 'name_de']}
                  type={i == 0 ? 'info' : 'neutral'}
                />
              ))}
            </div>
          </>
        )}
      </>
    </Card>
  );
};

export default Tester;
