import React, { useState } from 'react';
import { Button, TextField, Spacer, Link as PyreneLink, Banner, Heading } from '@osag/pyrene';

import { useLocation, useNavigate } from 'react-router-dom';

import { isAuthError, useAuth } from 'context/auth';
import { useIntl } from 'react-intl';

type LocationState = {
  referrer: string;
};

const errorCodeToKey: Record<string, string> = {
  'auth/invalid-email': 'auth.common.error.invalidemail',
  'auth/wrong-password': 'auth.signin.error.invalidpassword',
  'auth/user-not-found': 'auth.signin.error.invalidpassword',
};

const Signin = (): React.ReactElement => {
  const intl = useIntl();

  const { signin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const onSubmit = async () => {
    try {
      await signin(email, password);
      navigate((location?.state as LocationState)?.referrer || '/');
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
      if (isAuthError(e)) {
        setError(e.code);
      }
    }
  };

  return (
    <>
      <Heading level={3}>{intl.formatMessage({ id: 'auth.signin.title' })}</Heading>
      {error && (
        <>
          <Banner type="error" label={intl.formatMessage({ id: 'auth.signin.error.title' })}>
            {intl.formatMessage({ id: errorCodeToKey[error] || 'auth.signin.error.generic' })}
          </Banner>
          <Spacer size="medium" />
        </>
      )}
      <TextField
        title={intl.formatMessage({ id: 'auth.common.email' })}
        name="email"
        value={email}
        onChange={(v) => setEmail(v)}
      />
      <Spacer size="small" />
      <TextField
        title={intl.formatMessage({ id: 'auth.common.password' })}
        secret
        name="password"
        value={password}
        onChange={(v) => setPassword(v)}
      />
      <Spacer size="small" />
      <Button
        type="primary"
        onClick={onSubmit}
        label={intl.formatMessage({ id: 'auth.signin.submit' })}
      />
      <Spacer size="medium" />
      <PyreneLink
        onClick={() => navigate('/auth/forgot')}
        label={intl.formatMessage({ id: 'auth.signin.forgot' })}
      />
      <PyreneLink
        onClick={() => navigate('/auth/signup')}
        label={intl.formatMessage({ id: 'auth.signin.signup' })}
      />
    </>
  );
};

export default Signin;
