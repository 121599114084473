import React, { createContext, useContext, useEffect, useState } from 'react';

import axios from 'axios';

import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setCurrentScreen } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import { getRemoteConfig, fetchAndActivate, getValue, Value } from 'firebase/remote-config';

type FirebaseInit = {
  appId?: string;
};

type Firebase = {
  logEvent: (eventName: string, logData: { [key: string]: string | number }) => void;
  logUrl: (url: string) => void;
  getConfig: (key: string) => Value | null;
};

export const FirebaseContext = createContext<Firebase>({
  logEvent: () => {
    /* default init */
  },
  logUrl: () => {
    /* default init */
  },
  getConfig: () => null,
});

export interface Props {
  children: React.ReactElement;
  loader: React.ReactElement;
}

export const FirebaseContextProvider = ({ children, loader }: Props): React.ReactElement => {
  const [appInitialised, setAppInitialised] = useState<boolean>(false);
  const [analyticsInitialised, setAnalyticsInitialised] = useState<boolean>(false);

  const logAnalyticsEvent = (eventName: string, logData: { [key: string]: string | number }) => {
    if (analyticsInitialised) {
      logEvent(getAnalytics(), eventName, logData);
    }
  };

  const logAnalyticsUrl = (url: string) => {
    if (analyticsInitialised) {
      setCurrentScreen(getAnalytics(), url);
    }
  };

  const getConfig = (key: string) => {
    if (analyticsInitialised) {
      return getValue(getRemoteConfig(), key);
    }
    return null;
  };

  useEffect(() => {
    axios
      .get<FirebaseInit>('/__/firebase/init.json')
      .then(async (response) => {
        const app = initializeApp(await response.data);

        // in local emulator mode there is no appId
        if (response.data.appId) {
          getAnalytics();
          getPerformance(app);
          await fetchAndActivate(getRemoteConfig());
          setAnalyticsInitialised(true);
        }

        setAppInitialised(true);
      })
      .catch(() => {
        //
      });
  }, []);

  if (!appInitialised) {
    return loader;
  }

  return (
    <FirebaseContext.Provider
      value={{
        logEvent: logAnalyticsEvent,
        logUrl: logAnalyticsUrl,
        getConfig,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = (): Firebase => useContext(FirebaseContext);
