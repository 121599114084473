import React, { useEffect } from 'react';
import { Button, Heading, Link as PyreneLink, Spacer } from '@osag/pyrene';

import { useLocation } from 'react-router-dom';
import qs from 'qs';

import { useIntl } from 'react-intl';
import { useFirebase } from 'context/firebase';

const Register = (): React.ReactElement => {
  const intl = useIntl();
  const { search } = useLocation();
  const { pin } = qs.parse(search, { ignoreQueryPrefix: true });
  const { getConfig, logEvent } = useFirebase();
  const registerDomain = getConfig('device_register_domain')?.asString() || 'device.sighub.io';
  const dashboardUrl = getConfig('dashboard_url')?.asString() || 'https://dashboard.sighub.io';

  useEffect(() => {
    if (!pin && window.location.hostname === registerDomain) {
      window.location.replace(dashboardUrl);
    }
  }, [pin, registerDomain, dashboardUrl]);

  useEffect(() => {
    logEvent('Config:Clients:Register', { pin: pin as string });
  }, [pin, logEvent]);

  if (!pin) {
    return (
      <>
        <Heading level={4}>{intl.formatMessage({ id: 'register.done' })}</Heading>
        <Spacer size="medium" />
        {intl.formatMessage(
          { id: 'register.done.dashboard' },
          {
            link: ([t]) => <PyreneLink type="inline" path={dashboardUrl} label={t} />,
          }
        )}
      </>
    );
  }

  return (
    <>
      {intl.formatMessage({ id: 'register.info' })}
      <Spacer size="medium" />
      <Button
        label={intl.formatMessage({ id: 'register.action' })}
        onClick={() => {
          window.location.href = `https://${pin}.${registerDomain}/`;
        }}
      />
    </>
  );
};

export default Register;
