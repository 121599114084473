import React, { createContext, useContext, useEffect, useState } from 'react';

import {
  getAuth,
  onAuthStateChanged,
  setPersistence,
  createUserWithEmailAndPassword,
  browserSessionPersistence,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth';

export enum AuthStatus {
  Unknown,
  LoggedIn,
  LoggedOut,
}

type User = {
  email: string;
  displayName: string;
  uid: string;
};

type Auth = {
  signin: (email: string, password: string) => Promise<void>;
  tokenSignin: (token: string) => Promise<void>;
  signup: (email: string, password: string) => Promise<void>;
  forgot: (email: string) => Promise<void>;
  signout: () => Promise<void>;
  getIdToken: () => Promise<string>;
  isLoggedIn: boolean;
  isUnknown: boolean;
  authStatus: AuthStatus;
  user: User | null;
  uid: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const isAuthError = (e: any): e is { code: string } => {
  return !!e && 'code' in e;
};

export const AuthContext = createContext<Auth>({
  signin: async () => {
    /** default */
  },
  tokenSignin: async () => {
    /** default */
  },
  signup: async () => {
    /** default */
  },
  signout: async () => {
    /** default */
  },
  forgot: async () => {
    /** default */
  },
  getIdToken: async () => {
    throw new Error('Not authenticated');
  },
  isLoggedIn: false,
  isUnknown: true,
  authStatus: AuthStatus.Unknown,
  user: null,
  uid: '',
});

export interface Props {
  children: React.ReactElement;
  loader: React.ReactElement;
}

export const AuthContextProvider = ({ children, loader }: Props): React.ReactElement => {
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [authStatus, setAuthStatus] = useState<AuthStatus>(AuthStatus.Unknown);

  useEffect(() => {
    onAuthStateChanged(getAuth(), (loggedInUser) => {
      if (loggedInUser) {
        setUser({
          email: loggedInUser.email || '',
          displayName: loggedInUser.displayName || '',
          uid: loggedInUser.uid || '',
        });
        loggedInUser
          .getIdToken()
          .then((t) => {
            setToken(t);
            setAuthStatus(AuthStatus.LoggedIn);
          })
          .catch(() => {
            /* FIXME */
          });
      } else {
        setUser(null);
        setAuthStatus(AuthStatus.LoggedOut);
      }
    });
  }, [setUser]);

  const signin = async (email: string, password: string) => {
    try {
      await setPersistence(getAuth(), browserSessionPersistence);
      const credentials = await signInWithEmailAndPassword(getAuth(), email, password);
      if (credentials?.user) {
        return;
      }
      throw new Error('Failed to login');
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
      throw e;
    }
  };

  const tokenSignin = async (t: string) => {
    try {
      await setPersistence(getAuth(), browserSessionPersistence);
      const credentials = await signInWithCustomToken(getAuth(), t);
      if (credentials?.user) {
        return;
      }
      throw new Error('Failed to login');
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
      throw e;
    }
  };

  const signup = async (email: string, password: string) => {
    try {
      await createUserWithEmailAndPassword(getAuth(), email, password);
      return;
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
      throw e;
    }
  };

  const forgot = async (email: string) => {
    try {
      await sendPasswordResetEmail(getAuth(), email);
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
      throw e;
    }
  };

  const signout = async () => {
    try {
      await signOut(getAuth());
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
      throw e;
    }
  };

  const getIdToken = async () => {
    if (typeof token !== 'string') {
      throw new Error('not authenticated!');
    }

    return token;
  };

  const isLoggedIn = authStatus === AuthStatus.LoggedIn;
  const isUnknown = authStatus === AuthStatus.Unknown;
  const uid = user?.uid || '';

  if (isUnknown) {
    return loader;
  }

  return (
    <AuthContext.Provider
      value={{
        signin,
        tokenSignin,
        signout,
        forgot,
        signup,
        getIdToken,
        isLoggedIn,
        authStatus,
        isUnknown,
        user,
        uid,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): Auth => useContext(AuthContext);
