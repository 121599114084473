import React from 'react';
import { Spacer } from '@osag/pyrene';
import Card from 'components/card';

import Authlist from '../config/authlist';

import { useIntl } from 'react-intl';
import { useSelection } from 'context/selection';
import Heading from 'components/heading';

const RequestsView = (): React.ReactElement => {
  const intl = useIntl();
  const { angel } = useSelection();

  return (
    <Card>
      <>
        <Heading
          pill={angel ? angel.requests : 0}
          title={intl.formatMessage({ id: 'config.whitelist.authlist.title' })}
        />
        <Spacer size="medium" />
        <Authlist />
      </>
    </Card>
  );
};

export default RequestsView;
