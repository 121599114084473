import React, { useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';

import { useFirebase } from 'context/firebase';
import { useAuth } from 'context/auth';
import Auth from './auth';

import Layout from './layout';
import Status from './status';
import Account from './account';
import Config from './config';
import Register from './register';
import Activity from './activity';
import Error from './error';

export const NAVIGATION_LINKS = [
  { path: '/status', key: 'status' },
  { path: '/activity', key: 'activity' },
  { path: '/config', key: 'protection' },
  { path: '/account', key: 'account' },
];

const App = (): React.ReactElement => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();
  const { logUrl, getConfig } = useFirebase();
  const registerDomain = getConfig('device_register_domain')?.asString() || 'device.sighub.io';

  useEffect(() => {
    logUrl(location.pathname);
  }, [location, logUrl]);

  return (
    <Layout
      simple={
        location.pathname.startsWith('/auth') || window.location.hostname.includes(registerDomain)
      }
    >
      <Routes>
        {/* Special route for device registration. */}
        {window.location.hostname.includes(registerDomain) && <Route element={<Register />} />}
        <Route path="/auth/*" element={<Auth />} />
        {isLoggedIn && <Route path="/" element={<Navigate to="/status" />} />}
        {isLoggedIn && <Route path="/status" element={<Status />} />}
        {isLoggedIn && <Route path="/account" element={<Account />} />}
        {isLoggedIn && <Route path="/activity" element={<Activity />} />}
        {isLoggedIn && <Route path="/config" element={<Config />} />}
        {!isLoggedIn && (
          <Route
            path="/"
            element={<Navigate to="/auth/signin" state={{ referrer: location.pathname }} />}
          />
        )}
        <Route element={<Error />} />
      </Routes>
    </Layout>
  );
};

export default App;
