import { useApi } from 'context/api';
import { Angel, AngelInfo } from 'context/api/types';
import { useAuth } from 'context/auth';
import React, { createContext, useContext, useState, useEffect } from 'react';

type Selection = {
  angel: Angel | null;
  availableAngels: AngelInfo[] | null;
  selectAngel: (selectedAngel: string) => void;
  updateAngel: (angelUpdate: Partial<Angel>) => Promise<void>;
  applyConfig: () => Promise<void>;
};

export const SelectionContext = createContext<Selection>({
  angel: null,
  availableAngels: null,
  selectAngel: () => {
    /* empty initializer */
  },
  updateAngel: async () => {
    /* empty initializer */
  },
  applyConfig: async () => {
    /* empty initializer */
  },
});

export interface Props {
  children: React.ReactNode;
  loader: React.ReactElement;
}

export const SelectionProvider = ({ children, loader }: Props): React.ReactElement => {
  const { isLoggedIn } = useAuth();
  const api = useApi();
  const [availableAngels, setAvailableAngels] = useState<AngelInfo[] | null>(null);
  const [selectedAngelId, setSelectedAngelId] = useState<string | null>(null);
  const [angel, setAngel] = useState<Angel | null>(null);

  useEffect(() => {
    api.onAngelUpdate((a) => {
      setAngel(a);
    });
  }, [api]);

  useEffect(() => {
    if (isLoggedIn) {
      api
        .getAngels()
        .then((angels) => {
          setAvailableAngels(angels || []);

          if (angels?.length) {
            const previouslySelectedAngelId = localStorage.getItem('sighub.selected-angel');
            if (angels.find((d) => d.id === previouslySelectedAngelId)) {
              setSelectedAngelId(previouslySelectedAngelId);
            } else {
              setSelectedAngelId(angels[0].id);
            }
          }
        })
        .catch(() => {
          /* FIXME */
        });
    } else {
      setAvailableAngels([]);
      setSelectedAngelId(null);
      setAngel(null);
    }
  }, [isLoggedIn, api]);

  useEffect(() => {
    if (selectedAngelId) {
      setAngel(null);
      api
        .getAngel(selectedAngelId)
        .then((a) => {
          setAngel(a);
        })
        .catch(() => {
          /* FIXME */
        });
    }
  }, [selectedAngelId, api]);

  useEffect(() => {
    if (angel?.status.locked || angel?.status.recentConfigUpdate) {
      const interval = setInterval(() => {
        api
          .getAngel(angel.id)
          .then((polledAngel) => {
            setAngel(polledAngel);
            if (!polledAngel.status.locked && !angel.status.recentConfigUpdate) {
              clearInterval(interval);
            }
          })
          .catch(() => {
            /* FIXME */
          });
      }, 10 * 1000);
      return () => clearInterval(interval);
    }
    return () => null;
  }, [angel, api]);

  const selectAngel = (angelId: string) => {
    localStorage.setItem('sighub.selected-angel', angelId);
    setSelectedAngelId(angelId);
  };

  const updateAngel = async (angelUpdate: Partial<Angel> | undefined) => {
    if (angel) {
      if (angelUpdate) {
        setAngel({ ...angel, ...angelUpdate });
        const updated = await api.patchAngel(angel.id, angelUpdate);
        setAngel(updated);
      } else {
        const a = await api.getAngel(angel.id);
        setAngel(a);
      }
    }
  };

  const applyConfig = async () => {
    if (angel) {
      const updatedAngel = await api.applyConfig(angel.id);
      setAngel(updatedAngel);
    }
  };

  if (availableAngels === null) {
    return loader;
  }

  return (
    <SelectionContext.Provider
      value={{
        angel,
        availableAngels,
        selectAngel,
        updateAngel,
        applyConfig,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};

export const useSelection = (): Selection => useContext(SelectionContext);
