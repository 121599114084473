import React from 'react';

import { useIntl } from 'react-intl';
import { Heading, Link as PyreneLink, Loader, Spacer } from '@osag/pyrene';
import Card from 'components/card';

import { useSelection } from 'context/selection';
import {
  faPowerOff,
  faPlug,
  faDownload,
  faCheck,
  faArrowCircleDown,
  faHammer,
  faShoppingCart,
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';

import StatusIcon from 'components/statusIcon/statusIcon';
import styles from './angel.css';
import { useFirebase } from 'context/firebase';

const AngelStatusView = (): React.ReactElement => {
  const intl = useIntl();
  const { logEvent } = useFirebase();
  const { angel, availableAngels, applyConfig } = useSelection();

  const apply = () => {
    logEvent('Config:Apply', { location: 'status' });
    applyConfig().catch(() => {
      /* FIXME */
    });
  };

  if (availableAngels && !availableAngels.length) {
    return (
      <Card>
        <>
          <Heading level={3}>CyberAngel</Heading>
          <div className={styles.box}>
            <StatusIcon
              icon={faShoppingCart}
              title={intl.formatMessage({ id: 'status.install.noangel.title' })}
              message={intl.formatMessage(
                { id: 'status.install.noangel.message' },
                {
                  link: ([t]) => (
                    <PyreneLink type="inline" path="https://sighub.io/home" label={t} />
                  ),
                }
              )}
            />
          </div>
        </>
      </Card>
    );
  }

  if (!angel) {
    return (
      <Card>
        <>
          <Heading level={3}>CyberAngel</Heading>
          <Spacer size="small" />
          <Loader type="inline" />
        </>
      </Card>
    );
  }

  if (angel.status.uninitialized) {
    return (
      <Card>
        <>
          <Heading level={3}>
            {`CyberAngel${
              angel.name === 'CyberAngel' || angel.name === 'Cyber Angel' ? '' : `: ${angel.name}`
            }`}
          </Heading>
          <div className={styles.box}>
            <StatusIcon
              icon={faHammer}
              title={intl.formatMessage({ id: 'status.install.uninitialized.title' })}
              message={intl.formatMessage(
                { id: 'status.install.uninitialized.message' },
                {
                  link: ([t]) => (
                    <PyreneLink type="inline" path="https://sighub.io/support" label={t} />
                  ),
                }
              )}
            />
          </div>
        </>
      </Card>
    );
  }

  return (
    <Card>
      <>
        <Heading level={3}>
          {`CyberAngel${angel.name === 'CyberAngel' ? '' : `: ${angel.name}`}`}
        </Heading>
        <div className={styles.box}>
          <StatusIcon
            title={intl.formatMessage({
              id: `status.angel.${angel.status.connected ? 'connected' : 'disconnected'}.title`,
            })}
            message={intl.formatMessage({
              id: `status.angel.${angel.status.connected ? 'connected' : 'disconnected'}.message`,
            })}
            icon={angel.status.connected ? faPlug : faPowerOff}
          />
          {angel.status.connected && (
            <StatusIcon
              title={intl.formatMessage({
                id: `status.angel.${angel.status.updated ? 'updated' : 'updating'}.title`,
              })}
              message={intl.formatMessage({
                id: `status.angel.${angel.status.updated ? 'updated' : 'updating'}.message`,
              })}
              icon={angel.status.updated ? faCheck : faDownload}
            />
          )}
          {angel.status.connected && angel.status.locked && !angel.status.pending && (
            <StatusIcon
              title={intl.formatMessage({ id: 'status.angel.locked.title' })}
              message={intl.formatMessage({ id: 'status.angel.locked.message' })}
              icon={faArrowCircleDown}
            />
          )}
          {angel.status.connected && angel.status.pending && !angel.status.locked && (
            <StatusIcon
              title={intl.formatMessage({ id: 'status.angel.pending.title' })}
              message={intl.formatMessage(
                { id: 'status.angel.pending.message' },
                {
                  link: ([t]) => <PyreneLink type="inline" onClick={() => apply()} label={t} />,
                }
              )}
              icon={faExclamationCircle}
            />
          )}
          {angel.status.connected &&
            !angel.status.locked &&
            !angel.status.pending &&
            angel.status.recentConfigUpdate && (
              <StatusIcon
                title={intl.formatMessage({ id: 'status.angel.recentConfigUpdate.title' })}
                message={intl.formatMessage({ id: 'status.angel.recentConfigUpdate.message' })}
                icon={faCheckCircle}
              />
            )}
        </div>
      </>
    </Card>
  );
};

export default AngelStatusView;
