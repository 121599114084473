import React, { useEffect, useState } from 'react';
import { Spacer, Modal, TextField, Checkbox, Heading } from '@osag/pyrene';

import { useIntl } from 'react-intl';
import { useApi } from 'context/api';
import { useSelection } from 'context/selection';
import { parse } from 'tldts';
import { useFirebase } from 'context/firebase';
import { getHostnameFromInput } from 'lib/url';

type Props = {
  authRequestDomain?: string;
  onClose: (reload: boolean) => void;
  block?: boolean;
};
const AddListModal = ({ authRequestDomain, onClose, block = false }: Props): React.ReactElement => {
  const intl = useIntl();
  const api = useApi();
  const { logEvent } = useFirebase();

  const [inputDomain, setInputDomain] = useState<string>('');
  const [whitelistDomain, setWhitelistDomain] = useState<string | null>(null);
  const [wildcard, setWildcard] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { angel } = useSelection();

  const addWhitelist = async () => {
    if (angel?.id && whitelistDomain) {
      logEvent(`Config:${block ? 'Black' : 'White'}list:Add`, {
        domain: whitelistDomain,
        authRequestDomain: authRequestDomain || '',
        angelId: angel.id,
      });
      setSubmitting(true);
      if (block) {
        await api.addBlacklist(angel.id, whitelistDomain);
      } else {
        await api.addWhitelist(angel.id, whitelistDomain, authRequestDomain);
      }
      setSubmitting(false);
      setInputDomain('');
      onClose(true);
    }
  };

  useEffect(() => {
    if (authRequestDomain) {
      setInputDomain(authRequestDomain);
    }
  }, [authRequestDomain]);

  useEffect(() => {
    const domainFromUrl = getHostnameFromInput(inputDomain);
    if (!domainFromUrl) {
      setWhitelistDomain(null);
      return;
    }

    const { domain: parsedSecondLevel, isIcann, isPrivate } = parse(domainFromUrl);
    if (wildcard && (isIcann || isPrivate)) {
      if (parsedSecondLevel) {
        setWhitelistDomain(`.${parsedSecondLevel}`);
      } else {
        setWhitelistDomain(null);
      }
    } else {
      setWhitelistDomain(domainFromUrl);
    }
  }, [inputDomain, wildcard]);

  return (
    <Modal
      rightButtonBarElements={[
        {
          type: 'secondary',
          label: intl.formatMessage({
            id: `config.${block ? 'black' : 'white'}list.add.action.cancel`,
          }),
          action: () => {
            onClose(false);
          },
        },
        {
          type: 'primary',
          label: intl.formatMessage({
            id: `config.${block ? 'black' : 'white'}list.add.action.add`,
          }),
          action: () => addWhitelist(),
          loading: submitting,
          disabled: submitting || !whitelistDomain,
        },
      ]}
      renderCallback={() => (
        <>
          {!authRequestDomain && (
            <TextField
              title={intl.formatMessage({
                id: `config.${block ? 'black' : 'white'}list.add.label.domain`,
              })}
              value={inputDomain}
              invalid={!!inputDomain && !whitelistDomain}
              onChange={(v) => setInputDomain(v)}
            />
          )}
          <Checkbox
            onChange={(v) => setWildcard(v)}
            value={wildcard}
            label={intl.formatMessage({
              id: `config.${block ? 'black' : 'white'}list.add.wildcard`,
            })}
          />
          <Spacer size="medium" />
          {intl.formatMessage({ id: `config.${block ? 'black' : 'white'}list.add.domain` })}:
          <Heading level={3}>{whitelistDomain || ' '}</Heading>
        </>
      )}
      size="small"
      title={intl.formatMessage({ id: `config.${block ? 'black' : 'white'}list.add.title` })}
      onClose={() => onClose(false)}
    />
  );
};

export default AddListModal;
