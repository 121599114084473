import { Loader, SingleSelect } from '@osag/pyrene';
import { useSelection } from 'context/selection';
import React, { useState } from 'react';

import styles from './selection.css';

type DevicesByCustomer = {
  [key: string]: {
    label: string;
    value: string;
    tags: string[];
  }[];
};

const Selection = (): React.ReactElement => {
  const { availableAngels, selectAngel, angel } = useSelection();
  const [open, setOpen] = useState<boolean>(false);

  const devicesByCustomer =
    availableAngels?.reduce(
      (a: DevicesByCustomer, d) => ({
        ...a,
        [d.customer.name]: [
          ...(a[d.customer.name] || []),
          { value: d.id, label: d.name, tags: [d.customer.name] },
        ],
      }),
      {}
    ) || {};

  const groupedOptions = Object.entries(devicesByCustomer).map(([customerName, devices]) => ({
    label: customerName,
    options: devices,
  }));

  if (availableAngels == null || availableAngels.length <= 1) {
    return <span />;
  }

  if (!angel) {
    return (
      <div className={styles.loader}>
        <Loader type="inline" />
      </div>
    );
  }

  return (
    <>
      {!open && (
        <div className={styles.angelName} onClick={() => setOpen(true)}>
          {angel?.name}
        </div>
      )}
      {open && (
        <SingleSelect
          onBlur={() => setOpen(false)}
          openMenuOnFocus
          autoFocus
          groupedOptions={groupedOptions}
          clearable={false}
          value={{ value: angel?.id, label: angel?.name }}
          onChange={({ value }) => selectAngel(value as string)}
        />
      )}
    </>
  );
};

export default Selection;
