import React, { useEffect, useState } from 'react';
import { Loader, SimpleTable, Link as PyreneLink } from '@osag/pyrene';

import { useIntl } from 'react-intl';
import { useApi } from 'context/api';
import { AuthRequest, Domain } from 'context/api/types';
import { useSelection } from 'context/selection';
import AddListModal from './addlistmodal';
import { useFirebase } from 'context/firebase';

const Authlist = (): React.ReactElement => {
  const intl = useIntl();
  const api = useApi();
  const { logEvent } = useFirebase();

  const [authRequests, setAuthRequests] = useState<AuthRequest[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [authRequestModalDomain, setAuthRequestModalDomain] = useState<string | null>(null);
  const { angel } = useSelection();
  const [deleting, setDeleting] = useState<Domain[]>([]);

  const deleteRequest = async (authRequestDomain: Domain) => {
    if (angel?.id) {
      logEvent('Config:AuthRequest:Delete', { authRequestDomain, angelId: angel.id });
      setDeleting([...deleting, authRequestDomain]);
      await api.deleteAuthRequest(angel.id, authRequestDomain);
      const r = await api.getAuthRequests(angel?.id);
      setAuthRequests(r.sort());
      setDeleting(deleting.filter((d) => d !== authRequestDomain));
    }
  };

  useEffect(() => {
    if (angel?.id) {
      setLoading(true);

      api
        .getAuthRequests(angel?.id)
        .then((r) => {
          setAuthRequests(r.sort());
          setLoading(false);
        })
        .catch(() => {
          /* FIXME */
        });
    }
  }, [angel?.id, api]);

  const onSubmit = (reload: boolean) => {
    if (reload && angel?.id) {
      api
        .getAuthRequests(angel?.id)
        .then((r) => {
          setAuthRequests(r.sort());
        })
        .catch(() => {
          /* FIXME */
        });
    }
    setAuthRequestModalDomain(null);
  };

  if (loading) {
    return <Loader type="inline" />;
  }

  return (
    <>
      {authRequestModalDomain && (
        <AddListModal authRequestDomain={authRequestModalDomain} onClose={(v) => onSubmit(v)} />
      )}
      {authRequests?.length ? (
        <SimpleTable
          data={authRequests || []}
          columns={[
            {
              headerName: intl.formatMessage({ id: 'config.whitelist.column.request' }),
              accessor: 'domain',
              id: 'domain',
            },
            {
              id: 'links',
              accessor: 'domain',
              width: 120,
              cellRenderCallback: ({ domain }) =>
                deleting.includes(domain) ? (
                  <Loader type="inline" />
                ) : (
                  <>
                    <PyreneLink
                      type="inline"
                      onClick={() => deleteRequest(domain)}
                      label={intl.formatMessage({ id: 'config.whitelist.action.delete.request' })}
                    />
                    {' | '}
                    <PyreneLink
                      type="inline"
                      onClick={() => setAuthRequestModalDomain(domain)}
                      label={intl.formatMessage({ id: 'config.whitelist.action.accept.request' })}
                    />
                  </>
                ),
            },
          ]}
        />
      ) : (
        intl.formatMessage({ id: 'config.whitelist.empty.request' })
      )}
    </>
  );
};

export default Authlist;
