import React, { useEffect, useState } from 'react';
import { Loader, SimpleTable, Spacer, Link as PyreneLink } from '@osag/pyrene';

import { useIntl } from 'react-intl';
import { useApi } from 'context/api';
import { Domain } from 'context/api/types';
import { useSelection } from 'context/selection';
import AddListModal from './addlistmodal';
import { useFirebase } from 'context/firebase';

const Whitelist = (): React.ReactElement => {
  const intl = useIntl();
  const api = useApi();
  const { logEvent } = useFirebase();

  const [whitelist, setWhitelist] = useState<Domain[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showAddListModal, setShowAddListModal] = useState<boolean>(false);
  const { angel } = useSelection();
  const [deleting, setDeleting] = useState<Domain[]>([]);

  const deleteWhitelist = async (domain: Domain) => {
    if (angel?.id) {
      logEvent('Config:Whitelist:Delete', { domain, angelId: angel.id });
      setDeleting([...deleting, domain]);
      await api.deleteWhitelist(angel.id, domain);
      const wl = await api.getWhitelist(angel.id);
      setWhitelist(wl);
      setDeleting(deleting.filter((d) => d !== domain));
    }
  };

  useEffect(() => {
    if (angel?.id) {
      setLoading(true);
      api
        .getWhitelist(angel?.id)
        .then((w) => {
          setWhitelist(w.sort());
          setLoading(false);
        })
        .catch(() => {
          /* FIXME */
        });
    }
  }, [angel?.id, api]);

  const onSubmit = (reload: boolean) => {
    if (reload && angel?.id) {
      api
        .getWhitelist(angel?.id)
        .then((w) => {
          setWhitelist(w.sort());
        })
        .catch(() => {
          /* FIXME */
        });
    }
    setShowAddListModal(false);
  };

  if (loading) {
    return <Loader type="inline" />;
  }

  return (
    <>
      {showAddListModal && <AddListModal onClose={(v) => onSubmit(v)} />}
      {whitelist?.length ? (
        <SimpleTable
          data={whitelist?.map((domain) => ({ domain })) || []}
          columns={[
            {
              headerName: intl.formatMessage({ id: 'config.whitelist.column.domain' }),
              accessor: 'domain',
              id: 'domain',
            },
            {
              id: 'links',
              accessor: 'domain',
              width: 120,
              cellRenderCallback: ({ domain }) =>
                deleting.includes(domain) ? (
                  <Loader type="inline" />
                ) : (
                  <PyreneLink
                    type="inline"
                    onClick={() => deleteWhitelist(domain)}
                    label={intl.formatMessage({ id: 'config.whitelist.action.delete.domain' })}
                  />
                ),
            },
          ]}
        />
      ) : (
        intl.formatMessage({ id: 'config.whitelist.empty.whitelist' })
      )}
      <Spacer size="small" />
      <PyreneLink
        label={intl.formatMessage({ id: 'config.whitelist.action.add' })}
        onClick={() => setShowAddListModal(true)}
      />
    </>
  );
};

export default Whitelist;
